// "Hot Module Replacement" enabled environment

export const environment = {
    production: false,
    hmr: true,
    appConfig: 'appconfig.json',
    appUrl: 'https://qametrikui-homol.qametrik.com/',
    serverUrl:'https://qametrikui-homol.qametrik.com/',
    deployUrl:'https://qametrikui-homol.qametrik.com/',
    clarityUrl: 'https://clarity.microsoft.com/projects/view/mpr9elld2x/dashboard?date=Last%203%20days',
    gitbookUrl: 'https://qametrik.gitbook.io/qametrik-devops/'
};
