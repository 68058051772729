import { Component, Injector, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    AvaliacaoDeAmbienteServiceProxy,
    DashTenantUsersDto,
} from "@shared/service-proxies/service-proxies";
import { MenuItem, SelectItem } from "primeng/api";

@Component({
    selector: "app-reports-host-tenant",
    templateUrl: "./reports-host-tenant.component.html",
    styleUrls: ["./reports-host-tenant.component.less"],
})
export class ReportsHostTenantComponent
    extends AppComponentBase
    implements OnInit
{
    datesOptions: SelectItem[] = [];
    selectedDate: string = "";
    items: MenuItem[] | undefined;
    isCard: boolean = true;
    currentDate: string = "";
    initDate: string = "";
    urlReport: SafeUrl;
    noDashs: boolean = false;
    isObjects: boolean = false;
    index: number = 0;
    extractionDates: string[] = [];

    constructor(
        injector: Injector,
        private domSanitizer: DomSanitizer,
        private avaliacaoDeAmbienteService: AvaliacaoDeAmbienteServiceProxy
    ) {
        super(injector);
        this.getDates();

        if (this.appSession.tenantId != null)
            this.getDatesExtracts(this.appSession.tenantId);
    }

    ngOnInit() {
        this.showPanel(0);
        this.createMenu();
        this.validDashs(this.appSession.configDashs);
    }

    async getDatesExtracts(tenantId: number) {
        await this.avaliacaoDeAmbienteService
            .getDatesObjectsExtracts(tenantId)
            .subscribe((resp) => {
                this.selectedDate = resp[0];
                resp.map((element) => {
                    this.datesOptions.push({
                        label: this.convertDate(element),
                        value: element,
                    });
                });
            });
    }

    convertDate(inputData: string): string {
        const partesData = inputData.split("-");
        return `${partesData[1]}-${partesData[0]}`;
    }

    setDateDrop() {
        if (this.index == 5)
            this.urlReport = this.domSanitizer.bypassSecurityTrustResourceUrl(
                `https://app1.qametrik.com/public/question/e0721218-45d9-41b2-a1c0-cc08c9831c4f?DataExtracao=${this.selectedDate}&TenantId=${this.appSession.tenantId}#hide_parameters=TenantId,DataExtracao&theme=transparent`
            );
        else if (this.index == 6)
            this.urlReport = this.domSanitizer.bypassSecurityTrustResourceUrl(
                `https://app1.qametrik.com/public/question/82f725b8-3ea4-43f6-8d64-18a18c9dfc3e?DataExtracao=${this.selectedDate}&TenantId=${this.appSession.tenantId}#hide_parameters=TenantId,DataExtracao&theme=transparent`
            );
        else
            this.urlReport = this.domSanitizer.bypassSecurityTrustResourceUrl(
                `https://app1.qametrik.com/public/question/ae95df68-cf3c-4176-850c-9c66ff6d66ce?DataExtracao=${this.selectedDate}&TenantId=${this.appSession.tenantId}#hide_parameters=TenantId,DataExtracao&theme=transparent`
            );
    }

    viewDropDate(): boolean {
        return this.isObjects && !this.noDashs;
    }

    getDates() {
        var currentDate = new Date();
        var initDate = new Date();

        initDate.setDate(initDate.getDate() - 30);

        this.currentDate = currentDate.toISOString().slice(0, 10);
        this.initDate = initDate.toISOString().slice(0, 10);
    }

    showPanel(index: number) {
        if (this.appSession.tenantId != null) {
            this.isObjects = false;
            if (index == 0 && this.appSession.configDashs.isRework)
                this.urlReport =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(
                        `https://app1.qametrik.com/public/question/c1a96ad5-05e0-4187-997a-13aa84a3ef1c?DataFinal=${this.currentDate}&TenantId=${this.appSession.tenantId}#hide_parameters=TenantId&theme=transparent`
                    );

            if (index == 1 && this.appSession.configDashs.isRework)
                this.urlReport =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(
                        `https://app1.qametrik.com/public/question/ce2b4c2a-58fe-4e7e-8b30-d4b6be511f41?DataInicial=${this.initDate}&DataFinal=${this.currentDate}&TenantId=${this.appSession.tenantId}#hide_parameters=TenantId&theme=transparent`
                    );

            if (index == 2 && this.appSession.configDashs.isNonCompliance)
                this.urlReport =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(
                        `https://app1.qametrik.com/public/question/965ad535-1957-4c0b-8965-654a6091deb8?DataInicial=${this.initDate}&DataFinal=${this.currentDate}&TenantId=${this.appSession.tenantId}#hide_parameters=TenantId&theme=transparent`
                    );

            if (index == 3 && this.appSession.configDashs.isGmud)
                this.urlReport =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(
                        `https://app1.qametrik.com/public/question/20c67f35-8a46-4f51-9062-40e714a18f9b?DataInicial=${this.initDate}&DataFinal=${this.currentDate}&UsuarioCliente=Informe%20o%20usuário&TenantId=${this.appSession.tenantId}#hide_parameters=TenantId&theme=transparent`
                    );

            if (index == 4)
                this.urlReport =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(
                        `https://app1.qametrik.com/public/question/93b3a291-b6d7-4cf8-8270-58307ca29619?DataInicial=${this.initDate}&DataFinal=${this.currentDate}&UsuarioCliente=Informe%20o%20nome&TenantId=${this.appSession.tenantId}#hide_parameters=TenantId&theme=transparent`
                    );

            if (index == 5)
                this.urlReport =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(
                        `https://app1.qametrik.com/public/question/5c3d1e68-39e0-484d-bb28-37856125a4ed?DemandaId=0&Modulo=Informe%20o%20Módulo&Usuario=Informe%20o%20Usuário&TenantId=${this.appSession.tenantId}#hide_parameters=TenantId&theme=transparent`
                    );

            if (index == 6) {
                this.isObjects = true;
                this.index = index;
                this.setDateDrop();
            }

            if (index == 7) {
                this.isObjects = true;
                this.index = index;
                this.setDateDrop();
            }

            if (index == 8) {
                this.isObjects = true;
                this.index = index;
                this.setDateDrop();
            }
        } else {
            if (index == 0)
                this.urlReport =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(
                        `https://app1.qametrik.com/public/question/2edf6cc1-df82-4ef0-9dce-6f4041012a59?DataInicial=${this.initDate}&DataFinal=${this.currentDate}&UsuarioCliente=Informe%20o%20nome#theme=transparent`
                    );

            if (index == 1)
                this.urlReport =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(
                        `https://app1.qametrik.com/public/question/10d93c6d-9561-4cce-8035-0ef7f669e1b6?DataInicial=${this.initDate}&DataFinal=${this.currentDate}&UsuarioCliente=Informe%20o%20nome#theme=transparent`
                    );

            if (index == 2)
                this.urlReport =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(
                        `https://app1.qametrik.com/public/question/8d2eb8b0-9e94-488c-9d36-3a58499c2add?DataInicial=${this.initDate}&DataFinal=${this.currentDate}&Cliente=Informe%20o%20Cliente#theme=transparent`
                    );

            if (index == 3)
                this.urlReport =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(
                        `https://app1.qametrik.com/public/question/cd3b9899-459c-4146-9f33-3ec32cea8a68?DataInicial=${this.initDate}&DataFinal=${this.currentDate}&UsuarioCliente=Informe%20o%20nome#theme=transparent`
                    );

            if (index == 4) {
                if (this.appSession.tenantId != 5)
                    this.urlReport =
                        this.domSanitizer.bypassSecurityTrustResourceUrl(
                            `https://app1.qametrik.com/public/question/17e4ca62-6bb1-4f57-8a96-6257cc55b79d?DataInicial=${this.initDate}&DataFinal=${this.currentDate}#hide_parameters=TenantId&theme=transparent`
                        );
                else
                    this.urlReport =
                        this.domSanitizer.bypassSecurityTrustResourceUrl(
                            `https://app1.qametrik.com/public/question/d3c6a61a-be81-45fc-9d4b-2fc37e95f51a?DataInicial=${this.initDate}&DataFinal=${this.currentDate}#hide_parameters=TenantId&theme=transparent`
                        );
            }

            if (index == 5)
                this.urlReport =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(
                        `https://app1.qametrik.com/public/question/51c7af51-75fb-4a59-8982-e1ac950e4a09?DataInicial=${this.initDate}&DataFinal=${this.currentDate}&UsuarioCliente=Informe%20o%20nome#theme=transparent`
                    );
        }
    }

    createMenu() {
        if (this.appSession.tenantId != null) {
            this.items = [
                {
                    label: "Retrabalho",
                    disabled: !this.appSession.configDashs.isRework,
                    icon: "",
                    items: [
                        {
                            label: "Liberado Fora do Processo",
                            command: () => this.showPanel(0),
                        },
                        {
                            label: "Liberado Dentro do Processo",
                            command: () => this.showPanel(1),
                        },
                    ],
                },
                {
                    label: "Não Conformidade",
                    disabled: !this.appSession.configDashs.isNonCompliance,
                    icon: "",
                    items: [
                        // {
                        //   label: 'Request Criado Fora do Processo',
                        //   command: () => this.showPanel(2)
                        // },
                        {
                            label: "Request Liberado Fora do Processo",
                            command: () => this.showPanel(2),
                        },
                    ],
                },
                {
                    label: `Gmud's`,
                    disabled: !this.appSession.configDashs.isGmud,
                    icon: "",
                    items: [
                        {
                            label: `Gmud's Criadas Por Tipo`,
                            command: () => this.showPanel(3),
                        },
                    ],
                },
                {
                    label: `Request's`,
                    disabled: !this.appSession.configDashs.isRequest,
                    icon: "",
                    items: [
                        {
                            label: `Request's Transportadas`,
                            command: () => this.showPanel(4),
                        },
                    ],
                },
                {
                    label: `Timeline Demandas`,
                    disabled: !this.appSession.configDashs.isTimeline,
                    icon: "",
                    items: [
                        {
                            label: `Timeline Demandas Por Ambiente`,
                            command: () => this.showPanel(5),
                        },
                    ],
                },
                {
                    label: `Avaliação de Ambiente`,
                    disabled:
                        !this.appSession.configDashs.isEnvironmentalAssessment,
                    icon: "",
                    items: [
                        {
                            label: `Objetos Criados`,
                            command: () => this.showPanel(6),
                        },
                        {
                            label: `Objetos Criados com Débitos Técnicos`,
                            command: () => this.showPanel(7),
                        },
                        {
                            label: `Frequência de Débitos Técnicos`,
                            command: () => this.showPanel(8),
                        },
                    ],
                },
            ];
        } else {
            this.items = [
                {
                    label: "Avaliações de Usuários",
                    disabled: false,
                    items: [
                        {
                            label: `Avaliações de Usuários`,
                            disabled: false,
                            command: () => this.showPanel(0),
                        },
                        {
                            label: `Avaliações de Gestores`,
                            disabled: false,
                            command: () => this.showPanel(1),
                        },
                    ],
                },
                {
                    label: "Uso da Ferramenta",
                    disabled: false,
                    command: () => this.showPanel(2),
                },
                {
                    label: `Gmud's`,
                    disabled: false,
                    items: [
                        {
                            label: `Gmud's Criadas Por Clientes`,
                            disabled: false,
                            command: () => this.showPanel(3),
                        },
                        {
                            label: `Gmud's Criadas Por Tipo`,
                            disabled: false,
                            command: () => this.showPanel(4),
                        },
                    ],
                },
                {
                    label: `Request's `,
                    disabled: false,
                    items: [
                        {
                            label: `Request's Transportadas`,
                            disabled: false,
                            command: () => this.showPanel(5),
                        },
                    ],
                },
            ];
        }
    }

    validDashs(configDashs: DashTenantUsersDto) {
        if (
            !configDashs?.isRework &&
            !configDashs?.isNonCompliance &&
            !configDashs?.isGmud &&
            !configDashs?.isRequest &&
            !configDashs?.isTimeline &&
            !configDashs?.isEnvironmentalAssessment
        )
            this.noDashs = true;
    }
}
